import {type FC, type HTMLAttributes, memo, type ReactNode} from "react";
import {cva, type VariantProps} from "class-variance-authority";

const sectionStyles = cva(
  'section',
  {
    variants: {
      background: {
        brand: 'bg-brand text-white',
        white: 'bg-white',
        grey: 'bg-light-grey',
        black: 'bg-brand-black'
      },
      fullWidth: {
        false: 'container mx-auto',
      },
    },
    defaultVariants: {
      background: "white",
      fullWidth: false
    }
  }
);

const sectionWrapperStyle = cva(
  'section-wrapper py-6',
  {
    variants: {
      contained: {
        true: 'container mx-auto px-4'
      }
    },
    defaultVariants: {
      contained: true
    }
  }
)

interface Props extends HTMLAttributes<HTMLOrSVGElement>, VariantProps<typeof sectionStyles>, VariantProps<typeof sectionWrapperStyle> {
  children?: ReactNode,
  wrapperClassName?: string
  as?: keyof JSX.IntrinsicElements
}

const Section:FC<Props> = memo(function ({id, as: Wrapper = "section", children, background, className, fullWidth, contained, wrapperClassName = ''}) {
  return <Wrapper id={id} className={sectionStyles({background, className, fullWidth})}>
    <div className={sectionWrapperStyle({contained, className: wrapperClassName})}>
      {children}
    </div>
  </Wrapper>
});

Section.displayName = 'Section';
export { Section };